import { CSSProperties, FC } from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import Typography from '@/ui/components/typography';

const ParagraphRenderer: FC<{ data: string | { text: string }, style?: CSSProperties }> = ({
    data,
    style = {},
}) => {
    let content = null;

    const transformLink = (node: any, index: any) => {
        if (node.type === 'tag' && node.name === 'a') {
            // eslint-disable-next-line no-param-reassign
            node.attribs.style = 'text-decoration: underline';
        }

        return convertNodeToElement(node, index, transformLink);
    };

    if (typeof data === 'string') {
        content = data;
    } else if (typeof data === 'object' && data.text) {
        content = data.text;
    }

    return content ? (
        <Typography
            as="p"
            style={style}
            variant="normal"
        >{ReactHtmlParser(content, { transform: transformLink })}
        </Typography>
    ) : null;
};
export default ParagraphRenderer;
